.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #474747 !important;}


.slick-prev,
  .slick-next {
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  